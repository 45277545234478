<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <!-- <ul class="tabs_btn">
                <li :class="tab === 'global' ? 'active' : ''" @click="tab = 'global'">Settings</li>
                <li :class="tab === 'header' ? 'active' : ''" @click="tab = 'header'; goTo('hdrsection');">Header</li>
                <li :class="tab === 'cover' ? 'active' : ''" @click="tab = 'cover'; goTo('cvrsection');">Cover</li>
                <li :class="tab === 'footer' ? 'active' : ''" @click="tab = 'footer'; goTo('ftrsection');">Footer</li>
                <li :class="tab === 'delivery' ? 'active' : ''" @click="tab = 'delivery'">Delivery Message</li>
                <li :class="tab === 'seo' ? 'active' : ''" @click="tab = 'seo'">SEO</li>
                <li class="close_btn" @click="toggleSectionbar"><img src="@/assets/images/bar.svg"></li>
            </ul> -->
            <div class="tabs_content">
                <!-- <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button> -->
                <Form @submit="handleSubmit" v-slot="{ errors }" ref="page-setting-form" class="page-setting-form">
                    <div class="content_area">
                        <div class="info_bar">
                            <div class="step_bar2">
                                <ul>
                                    <li @click="toggleStep(1);" :class="{ 'active' : tab == 1 || tab > 1}">
                                        <span>1</span>
                                        <h6>Settings</h6>
                                    </li>
                                    <li @click="toggleStep(2)" :class="{ 'active' : tab == 2 || tab > 2}">
                                        <span>2</span>
                                        <h6>Public</h6>
                                    </li>
                                    <li @click="toggleStep(3);" :class="{ 'active' : tab == 3 || tab > 3}">
                                        <span>3</span>
                                        <h6>Delivery Message</h6>
                                    </li>
                                    <li @click="toggleStep(4)" :class="{ 'active' : tab == 4 || tab > 4 }">
                                        <span>4</span>
                                        <h6>SEO</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 3) * (tab - 1) }%`"></span></div>
                            </div>
                            <button type="button" class="tutorial_btn">Tutorial</button>
                        </div>
                        <ul class="btn_list" v-if="tab == 2 || tab == 4">
                            <li v-if="toggleAll" @click="openAllSection">Open All</li>
                            <li v-else @click="closeAllSection">Close All</li>
                        </ul>
                        <div class="content_wpr" v-if="tab == 1">
                            <div class="section_content w-100 open_area">
                                <div class="section_header" v-click-outside="closeDescription">
                                    <h2>Page Name</h2>
                                    <button type="button" class="setting_btn" @click="showDescription = !showDescription;"><i class="fas fa-cog"></i></button>
                                    <div class="setting_wpr description" :class="{'show' : showDescription}">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                                    <Field autocomplete="off" name="description" v-model="form.description">
                                                        <textarea name="description" id="description" v-model="form.description" cols="30" rows="10" placeholder="Description Goes Here.."></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="description" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <div class="field_wpr" :class="{ 'has-error': errors.title }">
                                                    <Field autocomplete="off" type="text" name="title" v-model="form.title" placeholder="ex: New Page" />
                                                </div>
                                                <ErrorMessage name="title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="capsule_elm pt-2">
                                            <h5>Make Private?<span>Turn On / Off to make it Private or public.</span></h5>
                                            <label for="make_private" class="switch_option capsule_btn border-0">
                                                <input type="checkbox" id="make_private" :true-value="1" :false-value="0" v-model="form.is_private" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="capsule_elm pt-2">
                                            <h5>Apply Saved Styling?</h5>
                                            <label for="apply_saved_styling" class="switch_option capsule_btn border-0">
                                                <input type="checkbox" id="apply_saved_styling" :true-value="1" :false-value="0" v-model="form.apply_saved_styling" @change="handleApplySavedStylingWarning()" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Page Description</h2>
                                </div>
                                <div class="setting_wpr">
                                    <div class="form_grp p-0 mt-2">
                                        <div class="group_item">
                                            <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                                <Field autocomplete="off" name="description" v-model="form.description" rules="required">
                                                    <textarea name="description" id="description" v-model="form.description" cols="10" rows="10" placeholder="Description Goes Here.."></textarea>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="description" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Page Settings</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="capsule_elm">
                                        <h5>Tagging</h5>
                                        <label for="order_completion" class="switch_option capsule_btn border-0">
                                            <input type="checkbox" id="order_completion" :true-value="1" :false-value="0" v-model="form.apply_tag" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div v-if="form.apply_tag">
                                        <h3 class="sub_header">Tag Option</h3>
                                        <div class="edit_section border">
                                            <div class="capsule_elm">
                                                <h5>View Page</h5>
                                                <label for="view" class="switch_option capsule_btn m-0">
                                                    <input type="radio" id="view" :value="1" v-model="form.tag_option" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Watch Video</h5>
                                                <label for="watch" class="switch_option capsule_btn m-0">
                                                    <input type="radio" id="watch" :value="2" v-model="form.tag_option" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Download Content</h5>
                                                <label for="download_content" class="switch_option capsule_btn m-0">
                                                    <input type="radio" id="download_content" :value="3" v-model="form.tag_option" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Button Clicked</h5>
                                                <label for="button_click" class="switch_option capsule_btn m-0">
                                                    <input type="radio" id="button_click" :value="4" v-model="form.tag_option" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="setting_wpr mt-3">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">
                                                        Select Tags
                                                        <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                                    </label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                                        <Field autocomplete="off" name="tag" v-model="form.tags">
                                                            <multiselect
                                                                v-model="form.tags"
                                                                mode="tags"
                                                                label="name"
                                                                value-prop="id"
                                                                :searchable="true"
                                                                :group-select="true"
                                                                :options="tagsGroup"
                                                                :groups="true"
                                                                group-label="type"
                                                                group-options="tags"
                                                                :group-hide-empty="true"
                                                                :loading="allTagLoader"
                                                                placeholder="Select Tags"
                                                            >
                                                                <template v-slot:grouplabel="{ group }">
                                                                    <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                                </template>
                                                                <template v-slot:option="{ option }">
                                                                    <span>{{ option.name }}</span>
                                                                </template>
                                                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                                    <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                      {{ option.name }}
                                                                      <span
                                                                        v-if="!disabled"
                                                                        class="multiselect-tag-remove"
                                                                        @mousedown.prevent="handleTagRemove(option, $event)"
                                                                      >
                                                                          <span class="multiselect-tag-remove-icon"></span>
                                                                      </span>
                                                                    </div>
                                                                </template>
                                                            </multiselect>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="tag" class="text-danger" />
                                                </div>
                                            </div>
                                            <div v-if="createTags" class="form_grp">
                                                <Form v-slot="{ errors }" ref="tag-form">
                                                    <div class="group_item">
                                                        <label class="input_label">Create a New Tag</label>
                                                        <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                            <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                                            <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                                        </div>
                                                        <ErrorMessage name="name" class="text-danger" />
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="tab == 2">
                            <div class="section_content w-100" ref="hdrsetting" @click="goTo('hdrsection')">
                                <div class="section_header">
                                    <h2>Header</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Company Name</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.company_branding }">
                                                        <Field autocomplete="off" type="text" name="company_branding" v-model="form.company_branding" placeholder="ex: Superfit Coaching" />
                                                    </div>
                                                    <ErrorMessage name="company_branding" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Header Background Color</label>
                                                    <Field autocomplete="off" name="headline_setting_bg_color" v-model="form.header_bgcolor" type="text" label="header background color">
                                                        <color-picker v-model="form.header_bgcolor"/>
                                                    </Field>
                                                    <ErrorMessage name="headline_setting_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Header Text Color</label>
                                                    <Field autocomplete="off" name="header_textcolor" v-model="form.header_textcolor" type="text" label="header text color">
                                                        <color-picker v-model="form.header_textcolor"/>
                                                    </Field>
                                                    <ErrorMessage name="header_textcolor" class="text-danger" />
                                                </div>
                                            </div>
                                            <ul class="tab_sec mt-2">
                                                <li @click="headerTab = 'logo'" :class="{ active: headerTab === 'logo' }">Header Logo</li>
                                                <li @click="headerTab = 'author'" :class="{ active: headerTab === 'author' }">Header Author Image</li>
                                            </ul>
                                            <div v-if="headerTab === 'logo'">
                                                <image-library v-model="form.logo" image-type="author-image" upload-text="Logo" />
                                            </div>
                                            <div v-if="headerTab === 'author'">
                                                <image-library v-model="form.author" image-type="author-image" upload-text="Author Image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('cvrsection')" ref="cvrsetting">
                                <div class="section_header">
                                    <h2>Cover</h2>
                                    <span class="status" :style="`color: ${form.display_cover ? '#2f7eed' : '#999'};`">{{ form.display_cover ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show <label v-if="!form.small_cover">Upload a small screen cover for a better view</label></div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="cover" class="switch_option capsule_btn border-0">
                                                <input type="checkbox" id="cover" :true-value="1" :false-value="0" v-model="form.display_cover" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="details_content">
                                                <div class="cover_type">
                                                    <ul class="type_list">
                                                        <li>
                                                            <label for="clr_text">
                                                                <input type="radio" id="clr_text" :value="1" v-model="form.cover_type" hidden>
                                                                <img src="@/assets/images/background.svg" alt="">
                                                                <h5>Color + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_text">
                                                                <input type="radio" id="img_text" :value="2" v-model="form.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_only">
                                                                <input type="radio" id="img_only" :value="3" v-model="form.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image Only</h5>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="setting_wpr" v-if="form.cover_type !== 3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Headline <button type="button" class="px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                                                <Field autocomplete="off" type="text" name="headline" v-model="form.headline" placeholder="Compelling Headline Goes Here" />
                                                            </div>
                                                            <ErrorMessage name="headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div v-show="headlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size</label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color</label>
                                                                <Field autocomplete="off" name="cover_textcolor" v-model="form.cover_textcolor" type="text" label="element color">
                                                                    <color-picker v-model="form.cover_textcolor" :classes="{ 'has-error': errors.cover_textcolor }" />
                                                                </Field>
                                                                <ErrorMessage name="cover_textcolor" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="capsule_elm">
                                                            <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                            <label for="headline_shadow" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div v-if="form.headline_setting.has_shadow">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-x</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="off" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-y</label>
                                                                    <div class="field_wpr ">
                                                                        <Field autocomplete="off" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-3">
                                                                <div class="group_item">
                                                                    <label class="input_label">Blur-Radius</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="off" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Shadow Color</label>
                                                                    <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                                                        <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.cover_type !== 3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Sub Headline <button type="button" class="px-1" @click="subHeadlineSetting = !subHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.subheadline }">
                                                                <Field autocomplete="off" type="text" name="subheadline" v-model="form.subheadline" placeholder="Enter text here" />
                                                            </div>
                                                            <ErrorMessage name="subheadline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="mb-4" v-show="subHeadlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size</label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color</label>
                                                                <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                                                    <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                                </Field>
                                                                <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="capsule_elm">
                                                            <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                            <label for="subheadline_shadow" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div v-if="form.subheadline_setting.has_shadow">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-x</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="off" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-y</label>
                                                                    <div class="field_wpr ">
                                                                        <Field autocomplete="off" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-3">
                                                                <div class="group_item">
                                                                    <label class="input_label">Blur-Radius</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="off" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Shadow Color</label>
                                                                    <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                                                        <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr mt-5" v-if="form.cover_type !== 1">
                                                    <h3 class="sub_header mt-1">Cover Image</h3>
                                                    <image-library v-model="form.cover" image-type="public-cover" upload-text="Cover" />
                                                    <div class="border-bottom">
                                                        <div class="capsule_elm">
                                                            <h5>Small Screen Image?</h5>
                                                            <label for="small_image" class="switch_option capsule_btn py-3">
                                                                <input type="checkbox" id="small_image" :true-value="1" :false-value="0" v-model="form.has_small_screen_cover" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <image-library v-if="form.has_small_screen_cover" v-model="form.small_cover" image-type="public-cover-small" upload-text="Cover" />
                                                    </div>
                                                    <div class="capsule_elm">
                                                        <h5>Add Image Overlay?</h5>
                                                            <label for="overlay" class="switch_option capsule_btn py-3">
                                                                <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.has_cover_overlay" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                    <div v-if="form.has_cover_overlay" class="setting_wpr mt-2">
                                                        <div class="color_container mt-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay Color</label>
                                                                <Field autocomplete="off" name="overlay_color" v-model="form.overlay_color" type="text" label="element color">
                                                                    <color-picker v-model="form.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                                                </Field>
                                                                <ErrorMessage name="overlay_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay opacity</label>
                                                                <div class="field_wpr" :class="{ 'has-error': errors.overlay_opacity }">
                                                                    <Field autocomplete="off" type="number" name="overlay_opacity" v-model="form.overlay_opacity" min="0" max="100" placeholder="68" />
                                                                </div>
                                                                <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.cover_type === 1">
                                                    <div class="group_item">
                                                        <label class="input_label">Cover Background</label>
                                                        <Field autocomplete="off" name="cover_background_color" v-model="form.cover_background_color" type="text" label="element color">
                                                            <color-picker v-model="form.cover_background_color" :classes="{ 'has-error': errors.cover_background_color }" />
                                                        </Field>
                                                        <ErrorMessage name="cover_background_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('ftrsection')" ref="ftrsetting">
                                <div class="section_header">
                                    <h2>Footer</h2>
                                    <span class="status" :style="`color: ${form.display_footer ? '#2f7eed' : '#999'};`">{{ form.display_footer ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 mb-2">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show</div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="has-login-footer" class="switch_option capsule_btn">
                                                <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.display_footer" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <p class="saved-style-text pt-1" @click="handleApplySavedStyle('footer')">Apply saved footer styling</p>
                                        <Transition duration="550" name="nested">
                                            <div class="details_content mt-4">
                                                <div class="capsule_elm">
                                                    <h5>Facebook</h5>
                                                    <label for="facebook" class="switch_option capsule_btn border-0">
                                                        <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.has_facebook" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr pb-3" v-if="form.has_facebook">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_facebook }">
                                                                <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                                            </div>
                                                            <ErrorMessage name="footer_facebook" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Twitter</h5>
                                                    <label for="twitter" class="switch_option capsule_btn border-0">
                                                        <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.has_twitter" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr pb-3" v-if="form.has_twitter">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_twitter }">
                                                                <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                                            </div>
                                                            <ErrorMessage name="footer_twitter" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Instagram</h5>
                                                    <label for="instagram" class="switch_option capsule_btn border-0">
                                                        <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.has_instagram" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr pb-3" v-if="form.has_instagram">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_instagram }">
                                                                <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                                            </div>
                                                            <ErrorMessage name="footer_instagram" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Linkedin</h5>
                                                    <label for="linkedin" class="switch_option capsule_btn border-0">
                                                        <input type="checkbox" id="linkedin" :true-value="1" :false-value="0" v-model="form.has_linkedin" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr pb-3" v-if="form.has_linkedin">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_linkedin }">
                                                                <Field autocomplete="off" type="text" name="footer_linkedin" v-model="form.footer_linkedin" placeholder="Linkedin link here" rules="url|validate_url:linkedin" label="linkedin" />
                                                            </div>
                                                            <ErrorMessage name="footer_linkedin" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Youtube</h5>
                                                    <label for="youtube" class="switch_option capsule_btn border-0">
                                                        <input type="checkbox" id="youtube" :true-value="1" :false-value="0" v-model="form.has_youtube" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr pb-3" v-if="form.has_youtube">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_youtube }">
                                                                <Field autocomplete="off" type="text" name="footer_youtube" v-model="form.footer_youtube" placeholder="Youtube link here" rules="url|validate_url:youtu" label="youtube" />
                                                            </div>
                                                            <ErrorMessage name="footer_youtube" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Icons Color</label>
                                                            <Field autocomplete="off" name="footer_textcolor" v-model="form.footer_textcolor" type="text" label="element color">
                                                                <color-picker v-model="form.footer_textcolor" :classes="{ 'has-error': errors.footer_textcolor }" />
                                                            </Field>
                                                            <ErrorMessage name="footer_textcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Background Color</label>
                                                            <Field autocomplete="off" name="footer_bgcolor" v-model="form.footer_bgcolor" type="text" label="element color">
                                                                <color-picker v-model="form.footer_bgcolor" :classes="{ 'has-error': errors.footer_bgcolor }" />
                                                            </Field>
                                                            <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Copyright Text</label>
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_company }">
                                                                <Field autocomplete="off" type="text" name="footer_company" v-model="form.footer_company" placeholder="© 2022 - Superfit Coaching" />
                                                            </div>
                                                            <ErrorMessage name="footer_company" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Terms Link</label>
                                                            <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.footer_terms }">
                                                                <Field autocomplete="off" type="text" name="footer_terms" v-model="form.footer_terms" placeholder="https://superfitcoaching.com" rules="url" label="term link" />
                                                                <span class="prefix">URL</span>
                                                            </div>
                                                            <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                            <ErrorMessage name="footer_terms" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Privacy Link</label>
                                                            <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.footer_policy }">
                                                                <Field autocomplete="off" type="text" name="footer_policy" v-model="form.footer_policy" placeholder="https://superfitcoaching.com" rules="url" label="privacy link" />
                                                                <span class="prefix">URL</span>
                                                            </div>
                                                            <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                            <ErrorMessage name="footer_policy" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="tab == 4">
                            <div class="section_content w-100" @click="goTo('seosection')" ref="seosetting">
                                <div class="section_header">
                                    <h2>SEO Settings</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Title</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_title }">
                                                        <Field autocomplete="off" type="text" name="seo_title" placeholder="ex: The title will be displayed in browser tabs." v-model="form.seo_title" />
                                                    </div>
                                                    <ErrorMessage name="seo_title" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Keywords</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_keyword }">
                                                        <Field autocomplete="off" type="text" name="seo_keyword" placeholder="ex: Several keywords that describe your page best." v-model="form.seo_keyword" />
                                                    </div>
                                                    <ErrorMessage name="seo_keyword" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Description</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.seo_desc }">
                                                        <Field autocomplete="off" type="textarea" name="seo_desc" v-model="form.seo_desc">
                                                            <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.seo_desc"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="seo_desc" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="capsule_elm">
                                            <h5>I want my page indexed by search engine</h5>
                                            <label for="page_indexed" class="switch_option capsule_btn border-0">
                                                <input type="checkbox" id="page_indexed" :true-value="1" :false-value="0" v-model="form.seo_index" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Tracking</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container  mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Tracking Code</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.fb_pixel }">
                                                        <Field autocomplete="off" type="textarea" name="fb_pixel" v-model="form.fb_pixel" label="description">
                                                            <textarea cols="10" rows="10" placeholder="Description Goes Here.." v-model="form.fb_pixel"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="fb_pixel" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('sharesection')" ref="sharesetting">
                                <div class="section_header">
                                    <h2>Social Share</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Title</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_title }">
                                                        <Field autocomplete="off" type="text" name="seo_share_title" placeholder="ex: New Title" v-model="form.seo_share_title" />
                                                    </div>
                                                    <ErrorMessage name="seo_share_title" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Description</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.seo_share_desc }">
                                                        <Field autocomplete="off" type="textarea" name="seo_share_desc" v-model="form.seo_share_desc">
                                                            <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.seo_share_desc"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="seo_share_desc" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="sub_heading2 mt-4">SEO Thumbnail</h3>
                                        <image-library v-model="form.seo_thumb" image-type="social-share" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('favsection')" ref="favsetting">
                                <div class="section_header">
                                    <h2>Favicon</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 pt-5">
                                        <image-library v-model="form.seo_fav" image-type="favicon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="tab == 3">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Delivery Message</h2>
                                </div>
                                <div class="color_container mt-2 mb-3 delivery-message">
                                    <div class="section_title m-0">
                                        <div class="mr-auto">{{form.delivery_notification_status ? 'Sending Method' : 'Notifications?'}}</div>
                                        <label for="notification" class="switch_option capsule_btn py-3">
                                            <input type="checkbox" id="notification" v-model="form.delivery_notification_status" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="pt-2" v-show="form.delivery_notification_status">
                                        <div class="section_wpr mb-2">
                                            <div class="edit_section border">
                                                <sending-method v-model="form.notification_method" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="form.delivery_notification_status">
                                    <div v-if="form.notification_method === 1 || form.notification_method === 3">
                                        <div class="setting_wpr mb-3">
                                            <email-component v-model="deliveryEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleDefaultEmail" preview-module="page" ref="email-component" />
                                        </div>
                                    </div>
                                    <div v-if="form.notification_method === 2 || form.notification_method === 3">
                                        <div class="setting_wpr">
                                            <sms-component v-model="form.asset_message.sms_message" :default-sms-handler="true" :handle-default-sms="handleDefaultSms" :media="smsMedia" mediaFieldName="smsMedia" preview-module="page" :update-sms-text="updateSmsText()" ref="sms-component" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true" v-if="tab !== 'drip'">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="pageUpdateLoader" type="button" class="btn cancel_btn" @click="closeModal()">Exit</button>
                        <button :disabled="pageUpdateLoader" class="btn save_btn" id="save-page-button"><i class="fa fa-spin fa-spinner" v-if="pageUpdateLoader"></i> {{ pageUpdateLoader ? 'Updating' : 'Update' }}</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-show="tab !== 3 && tab !== 4">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_page_setting" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_page_setting" v-model="previewPage" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <!-- <div class="small-cover-info" v-if="!form.small_cover">
                    <small>Upload a small screen cover for a better view</small>
                </div> -->
                <div class="cell">
                    <div class="content_area">
                        <div class="dashboard_content">
                            <div class="client_card border-0">
                                <div class="card_header pointer" :style="`background: ${form.header_bgcolor}; color: ${form.header_textcolor};`" @click="setting('hdrsetting')" ref="hdrsection">
                                    <nav>
                                        <img class="logo_img" :src="selectedPage.logo ? selectedPage.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                                        <a>
                                            <h4>Dashboard</h4>
                                        </a>
                                    </nav>
                                    <div class="user_box" @click="accountDrop = !accountDrop">
                                        <img :src="form.author ? form.author : user.profile_pic" :alt="user.full_name">
                                        <div class="user_info">
                                            <h5 :style="`color: ${form.header_textcolor};`">{{ user.full_name }} <i class="fas fa-caret-down" :class="`${accountDrop ? 'swing' : ''}`"></i></h5>
                                        </div>
                                        <ul class="account_drop text-inherit" :class="{ active : accountDrop }">
                                            <li><i class="fas fa-home"></i> Primary Location</li>
                                            <li><i class="far fa-user"></i> Profile Info</li>
                                            <li><i class="far fa-file"></i> My Files</li>
                                            <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card_body" v-show="form.display_cover && form.has_small_screen_cover" @click="setting('cvrsetting')" ref="cvrsection" :class="{'cover-image-sm' : form.cover_type == 1}">
                                    <!-- <picture v-if="(form.cover_type == 2 || form.cover_type == 3)">
                                        <source v-if="form.has_small_screen_cover && form.small_cover != null && form.small_cover != '' && form.small_cover != 'null'" :srcset="`${form.small_cover}`" media="(max-width: 768px)">
                                        <img :src="form.has_small_screen_cover && form.small_cover ? form.small_cover : (form.cover ? form.cover : require('@/assets/images/thumb/default-cover-3-2.svg'))" alt="" class="banner_img">
                                    </picture> -->
                                    <picture v-if="(form.cover_type == 2 || form.cover_type == 3)">
                                        <source v-if="form.small_cover != null && form.small_cover != '' && form.small_cover != 'null'" :srcset="`${form.small_cover}`" media="(max-width: 768px)">
                                        <img :src="form.small_cover ? form.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                    </picture>
                                    <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                                    <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                    <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                    <div class="banner_content">
                                        <h4 v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${ (form && form.headline_setting && form.headline_setting.font_size) ? (form.headline_setting.font_size / 3 < 20 ? 20 : form.headline_setting.font_size / 3 ): ''}px; line-height: ${(form && form.headline_setting && form.headline_setting.font_size) ? (form.headline_setting.font_size / 3 < 20 ? 26 : (parseInt(form.headline_setting.font_size) + 20) / 3) : ''}px; font-weight: ${form && form.headline_setting && form.headline_setting.font_weight}; font-family: ${form && form.headline_setting && form.headline_setting.font_family}; color: ${form.cover_textcolor}; ${form && form.headline_setting && form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                                        <h1 v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form && form.subheadline_setting ? (form.subheadline_setting.font_size / 3 < 11 ? 11 : form.subheadline_setting.font_size / 3) : ''}px; line-height: ${form && form.subheadline_setting ? (form.subheadline_setting.font_size / 3 < 11 ? 16 : (parseInt(form.subheadline_setting.font_size) + 20) / 3) : ''}px; font-weight: ${form && form.subheadline_setting ? form.subheadline_setting.font_weight : ''}; font-family: ${form && form.subheadline_setting ? form.subheadline_setting.font_family : ''}; color: ${form && form.subheadline_setting && form.subheadline_setting.font_color  ? form.subheadline_setting.font_color : ''}; ${form && form.subheadline_setting && form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.subheadline }}</h1>
                                    </div>
                                </div>
                            </div>
                            <ul class="mobile_menu w-100" v-if="selectedPage.display_page_offer || selectedPage.display_coach">
                                <li><i class="fas fa-angle-left"></i>Back</li>
                                <li @click="openSlide('offer')" v-if="selectedPage.display_page_offer">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.82682 7.14098C4.55389 5.71402 5.71402 4.55388 7.14098 3.82682C8.09748 3.33947 9.09148 3.15918 10.0908 3.07753C11.0377 3.00018 12.1873 3.0002 13.5199 3.00025L25.1389 3.00013C26.1675 2.99917 27.0742 2.99833 27.9552 3.20979C28.7289 3.39556 29.4686 3.70199 30.1471 4.11777C30.9196 4.59109 31.5602 5.23292 32.2869 5.96092L51.8948 25.569C53.3337 27.0079 54.5213 28.1953 55.4096 29.2418C56.3322 30.3285 57.0795 31.4284 57.5052 32.7379C58.1649 34.7688 58.1649 36.9564 57.5052 38.9873C57.0795 40.2967 56.3322 41.3966 55.4096 42.4833C54.5213 43.5298 53.3337 44.7172 51.8948 46.156L46.1561 51.8948C44.7172 53.3337 43.5296 54.5213 42.4833 55.4096C41.3966 56.3322 40.2967 57.0797 38.987 57.5052C36.9564 58.1649 34.7688 58.1649 32.7379 57.5052C31.4285 57.0797 30.3286 56.3322 29.2418 55.4096C28.1953 54.5213 27.008 53.3337 25.569 51.8948L5.96092 32.2869C5.23292 31.5601 4.59107 30.9196 4.11778 30.1473C3.70197 29.4688 3.39557 28.7289 3.20979 27.9551C2.99832 27.0744 2.99917 26.1674 3.00013 25.1389L3.00026 13.5199C3.00021 12.1873 3.00017 11.0377 3.07754 10.0909C3.15919 9.09148 3.33947 8.09748 3.82682 7.14098ZM20.6954 15.6393C17.9032 15.6393 15.6396 17.9029 15.6396 20.6951C15.6396 23.4873 17.9032 25.7508 20.6954 25.7508C23.4876 25.7508 25.7511 23.4873 25.7511 20.6951C25.7511 17.9029 23.4876 15.6393 20.6954 15.6393Z" fill="#999999"/>
                                    </svg>
                                    Offer
                                </li>
                                <li @click="openSlide('instructor')" v-if="selectedPage.display_coach">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.54548 45.7578C13.4779 39.23 21.2473 35 30.0002 35C38.7529 35 46.5224 39.23 51.4547 45.7578C51.4769 45.7873 51.4994 45.817 51.5219 45.8465C51.9459 46.4075 52.4007 47.0088 52.7257 47.589C53.1189 48.2913 53.4107 49.1035 53.3924 50.0915C53.3779 50.8855 53.1374 51.629 52.8452 52.216C52.5529 52.803 52.1049 53.443 51.4802 53.9335C50.6467 54.5875 49.7394 54.819 48.9334 54.9155C48.2264 55.0002 47.3884 55.0002 46.5459 55C46.5079 55 46.4699 55 46.4319 55H13.5682C13.5302 55 13.4923 55 13.4543 55C12.6118 55.0002 11.7738 55.0002 11.0667 54.9155C10.2608 54.819 9.35348 54.5875 8.52018 53.9335C7.89531 53.443 7.44726 52.803 7.15506 52.216C6.86286 51.629 6.62226 50.8855 6.60773 50.0915C6.58966 49.1035 6.88118 48.2913 7.27453 47.589C7.59948 47.0088 8.05423 46.4075 8.47831 45.8465C8.50081 45.817 8.52318 45.7873 8.54548 45.7578Z" fill="#999999"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.25 18.75C16.25 11.1561 22.4061 5 30.0001 5C37.5938 5 43.7501 11.1561 43.7501 18.75C43.7501 26.344 37.5938 32.5 30.0001 32.5C22.4061 32.5 16.25 26.344 16.25 18.75Z" fill="#999999"/>
                                    </svg>
                                    Coach
                                </li>
                            </ul>
                            <div class="left_side" :class="selectedPage.display_page_offer || selectedPage.display_coach ? 'mt-3' : 'mt-5'">
                                <div class="content_details">
                                    <div class="section_item media_title bord-t pt-0" v-if="selectedPage.display_media_headline == 1 && selectedPage.media_headline && selectedPage.media_headline_place == 'above'">
                                        <h3 class="m-0" :style="`justify-content: ${selectedPage.media_headline_position};`">
                                            {{ selectedPage.media_headline }}
                                        </h3>
                                    </div>
                                    <div class="mt-3" v-if="selectedPage.media.display == 'below'">
                                        <div class="section_item pb-3" v-if="selectedPage.display_content_headline == 1 && selectedPage.content_headline">
                                            <h3 class="m-0" :style="`justify-content: ${selectedPage.content_headline_position};`">
                                                {{ selectedPage.content_headline }}
                                            </h3>
                                        </div>
                                        <div v-if="selectedPage.display_content" class="section_item py-3">
                                            <p class="redactor-styles redactor-in para" v-html="selectedPage.content"></p>
                                        </div>
                                    </div>
                                    <div class="section_item video_wpr p-0" :class="{'t-round' : selectedPage.display_media_headline && selectedPage.media_headline_place == 'below', 'b-round' : selectedPage.display_media_headline && selectedPage.media_headline_place == 'above'}" v-if="selectedPage.display_media">
                                        <div class="video_wpr" v-if="(selectedPage.media.option == 1 && selectedPage.media.embed) || (selectedPage.media.option == 2 && selectedPage.media.url)">
                                            <div v-if="selectedPage.media.option == 2" v-html="parseEmbedCode(selectedPage.media.url)"></div>
                                            <div v-else v-html="selectedPage.media.embed"></div>
                                        </div>
                                        <img v-else :src="selectedPage.media.image ? selectedPage.media.image : require('@/assets/images/thumb/video-placeholder.svg')" />
                                    </div>
                                    <div class="section_item media_title bord-b" v-if="selectedPage.display_media_headline == 1 && selectedPage.media_headline && selectedPage.media_headline_place == 'below'">
                                        <h3 class="m-0" :style="`justify-content: ${selectedPage.media_headline_position};`">
                                            {{ selectedPage.media_headline }}
                                        </h3>
                                    </div>
                                    <div v-if="selectedPage.media.display == 'above'">
                                        <div class="section_item pb-3 pt-3" v-if="selectedPage.display_content_headline == 1 && selectedPage.content_headline">
                                            <h3 class="m-0" :style="`justify-content: ${selectedPage.content_headline_position};`">
                                                {{ selectedPage.content_headline }}
                                            </h3>
                                        </div>
                                        <div v-if="selectedPage.display_content" class="section_item pt-0">
                                            <p class="redactor-styles redactor-in para" v-html="selectedPage.content"></p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="section_item mt-5 coach_card" v-if="selectedPage.cta_button.display == 'display'"> -->
                                    <div class="button_list" v-if="selectedPage.cta_button.display == 'display'">
                                        <button v-if="selectedPage.cta_button.option == 'redirect'" :class="`button_${selectedPage.cta_button.position}`" class="justify-content-center pointer" @click="handleRedirectPage()" type="button" :style="`background-color: ${selectedPage.cta_button ? selectedPage.cta_button.btn_color : ''}; color: ${selectedPage.cta_button ? selectedPage.cta_button.btn_text_color : ''};`">{{ selectedPage.cta_button ? selectedPage.cta_button.text : 'Click Here' }}</button>
                                        <button v-else-if="selectedPage.cta_button.option == 'popup'" @click="offerPopup = true;" :class="`button_${selectedPage.cta_button.position}`" class="justify-content-center" type="button"  :style="`background-color: ${selectedPage.cta_button ? selectedPage.cta_button.btn_color : ''}; color: ${selectedPage.cta_button ? selectedPage.cta_button.btn_text_color : ''};`">{{ selectedPage.cta_button ? selectedPage.cta_button.text : 'Click Here' }}</button>
                                    </div>
                                <!-- </div> -->
                            </div>
                            <div class="right_side mb-4" v-if="selectedPage.offer_headline || selectedPage.display_coach || selectedPage.is_download">
                                <!-- <div class="section_item mb-5 coach_card" v-if="selectedPage.display_coach">
                                    <h3>{{ selectedPage.coach_headline ? selectedPage.coach_headline : 'Coach Headline' }}</h3>
                                    <div class="user_box">
                                        <img :src="selectedPage.coach_image ? selectedPage.coach_image : require('@/assets/images/male-female.png')" alt="chris">
                                        <div class="user_info">
                                            <h3>{{ selectedPage.coach_name ? selectedPage.coach_name : 'Joe Trainer' }}</h3>
                                            <h5>{{ selectedPage.coach_title ? selectedPage.coach_title : 'PHD, CSCS' }}</h5>
                                        </div>
                                    </div>
                                    <p v-html="form.coach_bio" v-if="form.coach_bio"></p>
                                </div>
                                <div class="section_item product_card mb-5" @click="selectedPage.offer_option == 'popup' ? offerPopup = true : offerPopup = false" v-if="selectedPage.display_page_offer">
                                    <h3 v-if="selectedPage.offer_headline && selectedPage.display_offer_headline" :style="`justify-content: ${selectedPage.offer_headline_position};`">{{ selectedPage.offer_headline }}</h3>
                                    <div v-if="selectedPage.display_page_offer">
                                        <a :href="selectedPage.offer_option == 'redirect' ? selectedPage.offer_url : 'javascript:void(0)'" :target="selectedPage.offer_option == 'redirect' ? '_blank': ''">
                                            <img :src="selectedPage.offer_image ? selectedPage.offer_image : require('@/assets/images/thumb/offer-placeholder.svg')" alt="product-image">
                                        </a>
                                    </div>
                                </div> -->
                                <div class="section_item p-0" v-if="selectedPage.is_download && downloads.length">
                                    <h3 class="m-0">Downloads</h3>
                                    <ul class="download_list">
                                        <li v-for="(download, d) of downloads" :key="d">
                                            <h5 v-if="['xlsx', 'xlsm', 'xls'].includes(download.ext)">
                                                <i class="far fa-file-excel mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                            </h5>
                                            <h5 v-else-if="['jpg', 'jpeg', 'png', 'pneg', 'gif', 'tif', 'bmp'].includes(download.ext)">
                                                <i class="far fa-file-image mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                            </h5>
                                            <h5 v-else-if="['pdf'].includes(download.ext)">
                                                <i class="far fa-file-pdf mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                            </h5>
                                            <h5 v-else-if="['mp4', 'mkv', 'wmv'].includes(download.ext)">
                                                <i class="far fa-file-video mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                            </h5>
                                            <h5 v-else-if="['docx', 'doc', 'docm'].includes(download.ext)">
                                                <i class="far fa-file-word mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                            </h5>
                                            <h5 v-else-if="['pptx', 'pptm', 'potx', 'potm', 'pot', 'ppsx'].includes(download.ext)">
                                                <i class="far fa-file-powerpoint mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                            </h5>
                                            <h5 v-else-if="['mp3', 'pcm', 'wav', 'aac','ogg', 'wma'].includes(download.ext)">
                                                <i class="far fa-file-audio mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                            </h5>
                                            <h5 v-else>
                                                <i class="far fa-file mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                            </h5>
                                            <span>
                                                <i class="fas fa-cloud-download-alt pointer" @click="handleFileDownload(download)"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <footer class="dashboard_footer" v-show="form.display_footer" @click="setting('ftrsetting')" ref="ftrsection" :style="`background: ${form.footer_bgcolor};`">
                                <ul>
                                    <li class="pointer" v-if="form.has_facebook"><a :href="form.footer_facebook" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                                    <li class="pointer" v-if="form.has_twitter"><a :href="form.footer_twitter" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                                    <li class="pointer" v-if="form.has_instagram"><a :href="form.footer_instagram" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                                    <li class="pointer" v-if="form.has_linkedin"><a :href="form.footer_linkedin" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                                    <li class="pointer" v-if="form.has_youtube"><a :href="form.footer_youtube" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
                                </ul>
                                <h4 :style="`color: ${form.footer_textcolor};`">{{ form.footer_company }}</h4>
                                <p>
                                    <a :href="form.footer_terms" :style="`color: ${form.footer_textcolor};`">Terms</a>
                                    <a :href="form.footer_policy" :style="`color: ${form.footer_textcolor};`">Privacy</a>
                                    <span v-html="form.white_label"></span>
                                </p>
                            </footer>
                        </div>
                    </div>
                    <div class="slide_box_container" :style="`pointer-events:${instructorDetails || offerDetails ? 'all' : 'none'}`">
                        <div class="slide_box" :class="{'active' : offerDetails}">
                            <button class="close_btn" @click="offerDetails = false;"><i class="fas fa-times"></i></button>
                            <div class="box_container">
                                <div class="section_item product_card" v-if="selectedPage.display_offer_headline">
                                    <h3 v-if="selectedPage.offer_headline && selectedPage.display_offer_headline" :style="`justify-content: ${selectedPage.offer_headline_position};`">{{ selectedPage.offer_headline }}</h3>
                                    <img :src="selectedPage.offer_image ? selectedPage.offer_image : require('@/assets/images/thumb/offer-placeholder.svg')" alt="product-image">
                                </div>
                            </div>
                        </div>
                        <div class="slide_box" :class="{'active' : instructorDetails}">
                            <button class="close_btn" @click="instructorDetails = false;"><i class="fas fa-times"></i></button>
                            <div class="box_container">
                                <div class="instructor">
                                    <h3>{{ selectedPage.coach_headline ? selectedPage.coach_headline : 'Coach Headline' }}</h3>
                                    <div class="user_box">
                                        <img :src="selectedPage.coach_image ? selectedPage.coach_image : require('@/assets/images/male-female.png')" alt="chris">
                                        <div class="user_info">
                                            <h3>{{ selectedPage.coach_name ? selectedPage.coach_name : 'Joe Trainer' }}</h3>
                                            <h5>{{ selectedPage.coach_title ? selectedPage.coach_title : 'PHD, CSCS' }}</h5>
                                        </div>
                                    </div>
                                    <p v-html="form.coach_bio" v-if="form.coach_bio"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab == 4">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="area_wpr">
                    <h3 class="sub_header" ref="seosection" @click="setting('seosetting')">Search Engine Listing</h3>
                    <div class="google_ranking">
                        <h6>About 5,29,000 results(0.36 seconds)</h6>
                        <h4>{{ form.seo_title }}</h4>
                        <p>{{ form.seo_desc }}</p>
                    </div>
                </div>
                <div class="area_wpr" ref="sharesection" @click="setting('sharesetting')">
                    <h3 class="sub_header">Social Share</h3>
                    <div class="seo_card">
                        <img :src="form.seo_thumb ? form.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                        <div class="seo_info">
                            <h3>{{ form.seo_share_title ? form.seo_share_title : 'Optimize Your Metabolism In 30 Days' }}</h3>
                            <p>{{ form.seo_share_desc ? form.seo_share_desc : 'It is easy to speed upa slow metabolism in as little as 30 days using this proven technique.' }}</p>
                        </div>
                    </div>
                </div>
                <div class="area_wpr" ref="favsection" @click="setting('favsetting')">
                    <h3 class="sub_header">Favicon</h3>
                    <div class="browser_layout">
                        <ul class="browser_tabs">
                            <li>
                                <img :src="form.seo_fav ? form.seo_fav : (env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png'))" alt="">
                                <h6>{{ form.seo_title ? (form.seo_title.length > 15 ? form.seo_title.substr(0, 15)+'...' : form.seo_title) : 'Thrive Coach' }}</h6>
                                <i class="fas fa-times"></i>
                            </li>
                            <li><i class="fas fa-plus"></i></li>
                        </ul>
                        <div class="browser_info">
                            <i class="fas fa-arrow-left"></i>
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-redo"></i>
                            <div class="browser_search">
                                <i class="fas fa-lock"></i>
                                <p>https://thrivecoach.io</p>
                                <i class="far fa-star ml-auto"></i>
                            </div>
                            <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab == 3">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_msg" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_msg" v-model="previewMsg" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="deliveryMessageTab = 'email'" :class="{'active' : deliveryMessageTab == 'email'}" v-if="form.notification_method == 1 || form.notification_method == 3">E-mail</li>
                    <li @click="deliveryMessageTab = 'sms'" :class="{'active' : deliveryMessageTab == 'sms'}" v-if="form.notification_method == 2 || form.notification_method == 3">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area" v-if="(form.notification_method === 3 && deliveryMessageTab == 'email') || form.notification_method == 1">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(deliveryEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="deliveryEmail.show_email_logo">
                                <img :src="deliveryEmail.email_logo ? deliveryEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(deliveryEmail.email, deliveryEmail.email_button_bg_color, deliveryEmail.email_button_text_color, 'View Page')"></div>
                            <div v-html="generateSignature(deliveryEmail.is_signature, deliveryEmail.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mt-2 mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area"  v-if="(form.notification_method === 3 && deliveryMessageTab == 'sms') || form.notification_method == 2" >
                        <div class="sms_preview">
                            <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(smsMessageText)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <offer-popup v-model="offerPopup" />
        <preview-page v-model="previewPage" />
        <preview-delivery-message v-model="previewMsg"/>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))
    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))
    const SmsComponent = defineAsyncComponent(() => import('@/components/SmsComponent'))
    const EmailComponent = defineAsyncComponent(() => import('@/components/EmailComponent'))
    const SendingMethod = defineAsyncComponent(() => import('@/components/SendingMethod'))
    const OfferPopup = defineAsyncComponent(() => import('@/pages/page/components/OfferPopup'))
    const PreviewPage = defineAsyncComponent(() => import('@/pages/page/components/PreviewPage'))
    const PreviewDeliveryMessage = defineAsyncComponent(() => import('@/pages/page/components/PreviewDeliveryMessage'))

    import Multiselect from '@vueform/multiselect'
    import Toastr from '@/utils/Toastr'
    import VideoParser from '@/utils/VideoParser'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Page Setting',

        data () {
            return {
                tab: 1,
                toggleAll: 1,
                showDescription: false,
                form: {
                    headline_setting: {},
                    subheadline_setting: {},
                    footer_bgcolor: '#f5f5f5',
                    apply_saved_styling: 0,
                },
                createTags: false,
                preview: true,
                headlineSetting: false,
                subHeadlineSetting: false,
                headerTab: 'logo',
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                },
                previewPage: false,
                accountDrop: false,
                offerPopup: false,
                env: {},
                deliveryMessageTab: 'email',
                whiteLabel: {},
                deliveryEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                smsMedia: [],
                previewMsg: false,
                smsMessageText: '',
                offerDetails: false,
                instructorDetails: false,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            Multiselect,
            ColorPicker,
            ImageLibrary,
            OfferPopup,
            PreviewPage,
            SmsComponent,
            EmailComponent,
            SendingMethod,
            PreviewDeliveryMessage,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                vm.resetForm();
            },

            previewPage (val) {
                if (val) {
                    const button = document.getElementById('save-page-button');

                    if (button) {
                        button.click();
                    }
                }
            },

            tab (val) {
                const vm = this;

                vm.toggleAll = 1;
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                fontFamily: state => state.commonModule.fontFamily,
                fontWeight: state => state.commonModule.fontWeight,
                tagsGroup: state => state.tagModule.tagsGroup,
                tagLoader: state => state.tagModule.tagLoader,
                selectedPage: state => state.pageModule.selectedPage,
                pageUpdateLoader: state => state.pageModule.pageUpdateLoader,
                downloads: state => state.pageModule.downloads,
                allTagLoader: state => state.tagModule.allTagLoader,
                pageDefaultContent: state => state.commonModule.pageDefaultContent,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.env = process.env;

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted() {
            const vm = this;
            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                updatePage: 'pageModule/updatePage',
                createTag: 'tagModule/createTag',
                getTags: 'tagModule/getTags',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;
                let headline_setting = vm.selectedPage.headline_setting ? JSON.parse(JSON.stringify(vm.selectedPage.headline_setting)) : {};
                let subheadline_setting = vm.selectedPage.subheadline_setting ? JSON.parse(JSON.stringify(vm.selectedPage.subheadline_setting)) : {};

                if (!headline_setting.font_size) {
                    headline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    };
                }

                if (!subheadline_setting.font_size) {
                    subheadline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    };
                }

                const applySavedStyling = vm.form.apply_saved_styling ? vm.form.apply_saved_styling : 0;

                vm.form = {
                    headline_setting,
                    subheadline_setting,
                    page_id: vm.selectedPage.id,
                    title: vm.selectedPage.title ? vm.selectedPage.title : '',
                    fb_pixel: vm.selectedPage.fb_pixel ? vm.selectedPage.fb_pixel : '',
                    apply_tag: vm.selectedPage.tag_option ? 1 : 0,
                    tag_option: vm.selectedPage.tag_option ? vm.selectedPage.tag_option : 1,
                    tags: vm.selectedPage.tags ? vm.selectedPage.tags : [],
                    company_branding: vm.selectedPage.company_branding ? vm.selectedPage.company_branding : '',
                    header_bgcolor: vm.selectedPage.header_bgcolor ? vm.selectedPage.header_bgcolor : '',
                    header_textcolor: vm.selectedPage.header_textcolor ? vm.selectedPage.header_textcolor : '',
                    logo: vm.selectedPage.logo ? vm.selectedPage.logo : '',
                    author: vm.selectedPage.author ? vm.selectedPage.author : '',
                    headline: vm.selectedPage.headline ? vm.selectedPage.headline : '',
                    subheadline: vm.selectedPage.subheadline ? vm.selectedPage.subheadline : '',
                    cover_type: vm.selectedPage.cover_type ? vm.selectedPage.cover_type : 2,
                    cover_textcolor: vm.selectedPage.cover_textcolor ? vm.selectedPage.cover_textcolor : '#FFF',
                    display_cover: vm.selectedPage.display_cover ? vm.selectedPage.display_cover : 0,
                    cover: vm.selectedPage.cover ? vm.selectedPage.cover : '',
                    has_small_screen_cover: vm.selectedPage.has_small_screen_cover ? vm.selectedPage.has_small_screen_cover : 0,
                    small_cover: vm.selectedPage.small_cover ? vm.selectedPage.small_cover : '',
                    has_cover_overlay: vm.selectedPage.has_cover_overlay ? vm.selectedPage.has_cover_overlay : 0,
                    overlay_color: vm.selectedPage.overlay_color ? vm.selectedPage.overlay_color : '',
                    overlay_opacity: vm.selectedPage.overlay_opacity ? vm.selectedPage.overlay_opacity : 0,
                    cover_background_color: vm.selectedPage.cover_background_color ? vm.selectedPage.cover_background_color : '',
                    display_footer: vm.selectedPage.display_footer ? vm.selectedPage.display_footer : 0,
                    has_facebook: vm.selectedPage.has_facebook ? vm.selectedPage.has_facebook : 0,
                    has_twitter: vm.selectedPage.has_twitter ? vm.selectedPage.has_twitter : 0,
                    has_instagram: vm.selectedPage.has_instagram ? vm.selectedPage.has_instagram : 0,
                    has_linkedin: vm.selectedPage.has_linkedin ? vm.selectedPage.has_linkedin : 0,
                    has_youtube: vm.selectedPage.has_youtube ? vm.selectedPage.has_youtube : 0,
                    footer_facebook: vm.selectedPage.footer_facebook ? vm.selectedPage.footer_facebook : '',
                    footer_twitter: vm.selectedPage.footer_twitter ? vm.selectedPage.footer_twitter : '',
                    footer_instagram: vm.selectedPage.footer_instagram ? vm.selectedPage.footer_instagram : '',
                    footer_linkedin: vm.selectedPage.footer_linkedin ? vm.selectedPage.footer_linkedin : '',
                    footer_youtube: vm.selectedPage.footer_youtube ? vm.selectedPage.footer_youtube : '',
                    footer_textcolor: vm.selectedPage.footer_textcolor ? vm.selectedPage.footer_textcolor : '',
                    footer_bgcolor: vm.selectedPage.footer_bgcolor ? vm.selectedPage.footer_bgcolor : '',
                    footer_company: vm.selectedPage.footer_company ? vm.selectedPage.footer_company : '',
                    footer_terms: vm.selectedPage.footer_terms ? vm.selectedPage.footer_terms : '',
                    footer_policy: vm.selectedPage.footer_policy ? vm.selectedPage.footer_policy : '',
                    seo_title: vm.selectedPage.seo_title ? vm.selectedPage.seo_title : '',
                    seo_keyword: vm.selectedPage.seo_keyword ? vm.selectedPage.seo_keyword : '',
                    seo_desc: vm.selectedPage.seo_desc ? vm.selectedPage.seo_desc : '',
                    seo_index: vm.selectedPage.seo_index ? vm.selectedPage.seo_index : '',
                    seo_share_title: vm.selectedPage.seo_share_title ? vm.selectedPage.seo_share_title : '',
                    seo_share_desc: vm.selectedPage.seo_share_desc ? vm.selectedPage.seo_share_desc : '',
                    seo_thumb: vm.selectedPage.seo_thumb ? vm.selectedPage.seo_thumb : '',
                    seo_fav: vm.selectedPage.seo_fav ? vm.selectedPage.seo_fav : '',
                    coach_bio: vm.selectedPage.coach_bio ? vm.selectedPage.coach_bio : '',
                    delivery_notification_status: vm.selectedPage.delivery_notification_status ? vm.selectedPage.delivery_notification_status : 0,
                    notification_method: vm.selectedPage.notification_method ? vm.selectedPage.notification_method : 3,
                    asset_message: vm.selectedPage.asset_message ? JSON.parse(JSON.stringify(vm.selectedPage.asset_message)) : {},
                    is_private: vm.selectedPage.is_private ? 1 : 0,
                    description: vm.selectedPage.description ? vm.selectedPage.description : '',
                    apply_saved_styling: applySavedStyling,
                };

                vm.deliveryEmail.subject                  = vm.selectedPage.asset_message && vm.selectedPage.asset_message.email_subject ? vm.selectedPage.asset_message.email_subject : '';
                vm.deliveryEmail.email                    = vm.selectedPage.asset_message && vm.selectedPage.asset_message.email_message ? vm.selectedPage.asset_message.email_message : '';
                vm.deliveryEmail.show_email_logo          = vm.selectedPage.show_email_logo ? vm.selectedPage.show_email_logo : 1;
                vm.deliveryEmail.email_logo               = vm.selectedPage.email_logo ? vm.selectedPage.email_logo : '';
                vm.deliveryEmail.is_signature             = vm.selectedPage.asset_message && vm.selectedPage.asset_message.is_signature ? 1 : 0;
                vm.deliveryEmail.signature_id             = vm.selectedPage.asset_message && vm.selectedPage.asset_message.signature_id ? vm.selectedPage.asset_message.signature_id : '';
                vm.deliveryEmail.module_id                = vm.selectedPage.id;
                vm.deliveryEmail.email_button_bg_color    = vm.selectedPage.asset_message && vm.selectedPage.asset_message.email_button_bg_color ? vm.selectedPage.asset_message.email_button_bg_color : '#2c3e50',
                vm.deliveryEmail.email_button_text_color  = vm.selectedPage.asset_message && vm.selectedPage.asset_message.email_button_text_color ? vm.selectedPage.asset_message.email_button_text_color : '#FFF',

                vm.smsMedia = vm.selectedPage.asset_message && vm.selectedPage.asset_message.media ? vm.selectedPage.asset_message.media : [];

                vm.tagForm = {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                };

                vm.deliveryMessageTab = 'email';
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;

                params.asset_message = {
                    email_subject: vm.deliveryEmail.subject,
                    email_message: vm.deliveryEmail.email,
                    show_email_logo: vm.deliveryEmail.email_logo ? 1 : 0,
                    page_image: vm.deliveryEmail.email_logo ? vm.deliveryEmail.email_logo : null,
                    is_signature: vm.deliveryEmail.is_signature,
                    signature_id: vm.deliveryEmail.signature_id,
                    sms_message: vm.form.asset_message.sms_message,
                    media: vm.smsMedia,
                    email_button_bg_color: vm.deliveryEmail.email_button_bg_color,
                    email_button_text_color: vm.deliveryEmail.email_button_text_color,
                };

                params.show_email_logo  = vm.deliveryEmail.show_email_logo,
                params.email_logo       = vm.deliveryEmail.email_logo ? vm.deliveryEmail.email_logo : null,
                params.setFieldError    = setFieldError;

                vm.updatePage(params).then((result) => {
                    if (result) {
                        vm.resetForm();
                    }
                });
            },

            setting (refName) {
                const vm = this;

                if (refName === 'hdrsetting') {
                    vm.tab = 'header';
                } else if (refName === 'cvrsetting') {
                    vm.tab = 'cover';
                } else if (refName === 'ftrsetting') {
                    vm.tab = 'footer';
                }

                setTimeout(function () {
                    let element = vm.$refs[refName];

                    setTimeout(function () {
                        let top = element.offsetTop;
                        document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                    }, 100);
                }, 100);
            },

            goTo (refName) {
                const vm = this;

                setTimeout(function () {
                    let element = vm.$refs[refName];

                    setTimeout(function() {
                        let top = element.offsetTop;
                        document.querySelector('.preview_content .content_area').scrollTo(0, top);
                    }, 100);
                }, 10);
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url)
            },

            handleDefaultEmail () {
                const vm = this;

                const email = `<p>Hi {{first_name}},</p>
                               <p>{{owner_first_name}} from {{company_name}} has shared a private page with you.</p>
                               <p>{{page_url_button}}</p>
                               <p><br>Cheers!</p>
                               <p>Team {{company_name}}</p>
                               <p><strong>Agreement to copyright:</strong> By accessing this information you agree to all copyrights by {{company_name}} to this intellectual property and agree not to duplicate, redistribute, or alter this information in any manner without written permission.</p>`;

                vm.deliveryEmail.subject = '[ACCESS] {{page_name}}';
                vm.deliveryEmail.email   = email;
            },

            handleDefaultSms () {
                const vm = this;

                vm.form.asset_message.sms_message = 'Hi {{first_name}}\r\n,{{owner_first_name}} from {{company_name}} has shared a private page with you.\r\n{{page_url_sms}}\r\nCheers!\r\nTeam {{company_name}}';
            },

            updateSmsText () {
                const vm = this;

                const messageSms = vm.$refs['sms-component'];

                if (messageSms) {
                    vm.smsMessageText = messageSms.smsMedia && messageSms.smsMedia.media && messageSms.smsMedia.media[0] ? vm.form.asset_message.sms_message + '\n\n' + messageSms.smsMedia.media[0].toString() : vm.form.asset_message.sms_message;
                }
            },

            toggleSectionbar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            handleApplySavedStyle (section = 'footer') {
                const vm = this;

                if (vm.pageDefaultContent) {
                    if (section == 'footer') {
                        vm.form.display_footer   = vm.pageDefaultContent.display_footer ? 1 : 0;
                        vm.form.footer_company   = vm.pageDefaultContent.footer_company;
                        vm.form.footer_bgcolor   = vm.pageDefaultContent.footer_bgcolor;
                        vm.form.footer_policy    = vm.pageDefaultContent.footer_policy;
                        vm.form.footer_terms     = vm.pageDefaultContent.footer_terms;
                        vm.form.footer_textcolor = vm.pageDefaultContent.footer_textcolor;
                        vm.form.footer_facebook  = vm.pageDefaultContent.footer_facebook;
                        vm.form.footer_twitter   = vm.pageDefaultContent.footer_twitter;
                        vm.form.footer_instagram = vm.pageDefaultContent.footer_instagram;
                        vm.form.footer_linkedin  = vm.pageDefaultContent.footer_linkedin;
                        vm.form.footer_youtube   = vm.pageDefaultContent.footer_youtube;
                        vm.form.has_instagram    = vm.pageDefaultContent.has_footer_instagram ? 1 : 0;
                        vm.form.has_facebook     = vm.pageDefaultContent.has_footer_facebook ? 1 : 0;
                        vm.form.has_twitter      = vm.pageDefaultContent.has_footer_twitter ? 1 : 0;
                        vm.form.has_linkedin     = vm.pageDefaultContent.has_footer_linkedin ? 1 : 0;
                        vm.form.has_youtube      = vm.pageDefaultContent.has_footer_youtube ? 1 : 0;
                    }
                }
            },

            toggleStep (step) {
                const vm = this;

                vm.tab = step;
            },

            toggleSection (ev) {
                const vm = this;
                let parentElm = ev.currentTarget.closest('.section_content');

                if (parentElm.classList.contains('show')) {
                    parentElm.classList.remove('show');
                    vm.toggleAll = 1;
                } else {
                    parentElm.classList.add('show');
                    vm.toggleAll = 0;
                }
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for(let i = 0; i < allParentElm.length; i++){
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },

            closeDescription () {
                const vm = this;

                vm.showDescription = false;
            },

            toggleDetails (ev) {
                let elm = ev.currentTarget;
                let targetElm = elm.closest('.color_container').querySelector('.details_content');

                if (targetElm.classList.contains('show')) {
                    targetElm.classList.remove('show');
                    elm.innerHTML = 'Open';
                } else {
                    targetElm.classList.add('show');
                    elm.innerHTML = 'Close';
                }
            },

            openSlide (type) {
                const vm = this;

                if (type == 'offer') {
                    vm.instructorDetails = false;
                    vm.offerDetails = true;
                } else if (type == 'instructor') {
                    vm.offerDetails = false;
                    vm.instructorDetails = true;
                }
            },

            handleApplySavedStylingWarning () {
                const vm = this;

                if (vm.form.apply_saved_styling) {
                    const options = Helper.swalConfirmOptions('Are you sure?', 'Enabling this will update your header, cover, footer, and more with saved styles, and this action cannot be undone.');

                    Swal.fire(options).then((result) => {
                        if (result.isDismissed) {
                            vm.form.apply_saved_styling = 0;
                        }
                    });
                }
            },
        },
    }
</script>

<style scoped>
    .toggle_details{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        margin: 0 15px;
        cursor: pointer;
    }
    .global_setting .color_container .details_content{
        display: none;
    }
    .global_setting .color_container .details_content.show{
        display: block;
    }
    .section_title{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
    .section_title label{
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #6a6a6a;
        display: block;
        padding: 3px 0;
    }
    .page-setting-form{
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    .cover_type {
        margin: 15px -30px 15px -30px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 30px;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }

    /* Preview area */
    .global_setting .preview_content {
        position: relative;
        width: 40%;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .dashboard_content .left_side,
    .dashboard_content .right_side {
        padding: 25px 15px;
        width: 100%;
    }

    .dashboard_content h3 {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 15px;
    }
    .dashboard_content .content_details{
        background: #fff;
        border-radius: 8px;
        padding: 20px;
    }
    :deep(.redactor-styles.redactor-in ul){
        padding-left: 20px;
    }
    .border-item {
        padding: 15px;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        background: #fff;
    }

    .dashboard_content .dotted_item h3 {
        align-items: center;
    }

    .dashboard_content .para {
        font-size: 16px;
        line-height: 32px;
        color: #585858;
        font-weight: 400;
    }

    .step_banner{
        margin-bottom: 5px;
    }

    .step_banner img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 6px;
    }

    .product_card {
        /* border: 1px solid #e9e9e9; */
        border-radius: 8px;
        overflow: hidden;
        padding: 0;
        line-height: 0;
        background: #fff;
    }

    .product_card h3 {
        padding: 15px 20px;
        margin: 0;
    }

    .product_card img {
        width: 100%;
        /* height: 130px; */
        object-fit: cover;
    }

    .coach_card {
        /* border: 1px solid #e9e9e9; */
        border-radius: 8px;
        padding: 15px 20px;
        background: #fff;
    }

    .coach_card .user_box {
        display: flex;
        align-items: center;
        margin-top: 15px;
    }

    .coach_card .user_box img {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }

    .coach_card .user_box .user_info h3 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin: 0;
    }

    .coach_card .user_box .user_info h5 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        margin: 5px 0 0 0;
    }

    :deep(.coach_card p) {
        font-size: 11px !important;
        line-height: 17px !important;
        font-weight: 300;
        color: #717171;
        margin: 15px 0 5px 0;
    }

    .button_list li {
        margin-top: 10px;
    }

    .button_list li button {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #fff;
        padding: 7px 10px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .download_list{
        background: transparent;
        padding: 15px 0 0 0;
        display: flex;
        flex-direction: column;
        gap: 0;
        border: 0;
        border-radius: 0;
    }

    .download_list li {
        background: #fff;
        border: 0;
        box-shadow: none;
        display: flex;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 15px;
    }

    .download_list li img {
        width: 40px;
        height: auto;
    }

    .download_list li h5 {
        padding: 12px 15px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .download_list li h5 label{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        padding-left: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .download_list li span {
        margin-left: auto;
        font-size: 15px;
        width: 44px;
        height: auto;
        border-left: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .client_card {
        width: 100%;
        border: 0;
    }

    .client_card .card_header {
        padding: 15px 20px;
    }

    .client_card .card_header h4 {
        font-size: 12px;
        line-height: 15px;
    }

    .client_card .card_header .user_box>img {
        width: 20px;
        height: 20px;
    }

    .client_card .card_header .user_box h5 {
        font-size: 11px;
        line-height: 13px;
    }

    .client_card .card_header .user_info {
        margin-left: 10px;
    }

    .client_card .card_header .user_info span {
        font-size: 10px;
        line-height: 12px;
    }

    .client_card .card_body {
        height: auto;
        text-align: center;
        line-height: 0;
    }

    .client_card .card_body .banner_img {
        position: relative;
    }

    .client_card .card_body .banner_content {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 2;
    }

    .client_card .card_body h1 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: #fff;
        /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.68);
        text-transform: uppercase; */
        position: relative;
        z-index: 3;
    }

    .client_card .card_body h4 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #fff;
        /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        /* letter-spacing: 2px; */
        /* text-transform: uppercase; */
        position: relative;
        z-index: 3;
    }

    .client_card .card_body .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    .dashboard_footer {
        width: 100%;
        text-align: center;
        padding: 20px 0;
        margin-top: 10px;
    }

    .dashboard_footer ul li {
        padding: 2px;
    }

    .dashboard_footer ul li img {
        width: 20px;
        height: 20px;
    }

    .dashboard_footer h4 {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    .global_setting .preview_content p {
        font-size: 16px;
        line-height: 32px;
    }

    .dashboard_footer p a:after {
        top: 5px;
        width: 2px;
        height: 2px;
    }

    .preview_content .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .preview_content .cell:after,
    .preview_content .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .preview_content .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .preview_content .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .preview_content .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #f5f5f5;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .forms-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .preview_content .dashboard_footer {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    .preview_content .dashboard_footer ul li {
        padding: 2px;
    }

    .preview_content .dashboard_footer ul li a {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    .preview_content .dashboard_footer h4 {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    .preview_content .dashboard_footer p {
        font-size: 9px;
        line-height: 11px;
    }

    .preview_content .dashboard_footer p a:after {
        top: 5px;
        width: 2px;
        height: 2px;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
    }

    .google_ranking {
        background: #fff;
        border-radius: 10px;
        max-width: 450px;
        padding: 30px;
        margin: 12px 0;
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
    }

    .google_ranking h6 {
        font-size: 12px;
        line-height: 14px;
        color: #70757a;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .google_ranking h4 {
        font-size: 15px;
        line-height: 20px;
        color: #1a0dab;
        font-weight: 400;
        padding: 5px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .google_ranking P {
        font-size: 12px;
        line-height: 17px;
        color: #4d5156;
        font-weight: 400;
        padding: 5px 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .seo_card {
        max-width: 450px;
        border: 1px solid #f5f5f5;
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        overflow: hidden;
        line-height: 0;
        margin: 12px 0;
    }

    .seo_card img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .seo_card .seo_info {
        padding: 25px 30px;
        background: #fff;
    }

    .seo_card .seo_info h3 {
        font-size: 17px;
        line-height: 22px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
        text-transform: capitalize;
    }

    .seo_card .seo_info p {
        font-size: 12px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .browser_layout {
        background: #f5f5f5;
        border-radius: 5px;
        padding: 5px 0 2px 0;
        max-width: 450px;
        margin: 12px 0;
    }

    .browser_tabs {
        display: flex;
        padding: 0 10px;
    }

    .browser_tabs li {
        padding: 7px 10px;
        display: flex;
        align-items: center;
        border-radius: 6px 6px 0 0;
        background: #fff;
        flex: 0 0 150px;
        position: relative;
    }

    .browser_tabs li:before {
        content: '';
        width: 8px;
        height: 8px;
        line-height: 16px;
        display: inline-block;
        background-image: radial-gradient(circle at 0 0%, rgba(0, 0, 0, 0) 8px, #fff 8px);
        position: absolute;
        bottom: 0;
        left: -8px;
    }

    .browser_tabs li:after {
        content: '';
        width: 8px;
        height: 8px;
        line-height: 16px;
        display: inline-block;
        background-image: radial-gradient(circle at 8px 0%, rgba(0, 0, 0, 0) 8px, #fff 8px);
        position: absolute;
        bottom: 0;
        right: -8px;
    }

    .browser_tabs li h6 {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .browser_tabs li i {
        margin-left: auto;
        font-size: 10px;
        color: #5a5a5a;
    }

    .browser_tabs li img {
        max-width: 15px;
        height: auto;
        margin-right: 7px;
    }

    .browser_tabs li:last-child {
        flex: 0 0 auto;
        background: transparent;
        padding: 10px;
        position: static;
    }

    .browser_tabs li:last-child i {
        color: #121525;
    }

    .browser_info {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 5px;
        margin: 0 2px;
        border-radius: 3px;
    }

    .browser_info i {
        font-size: 10px;
        margin: 0 5px;
    }

    .browser_info img.user {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
    }

    .browser_search {
        display: flex;
        align-items: center;
        background: #f5f5f5;
        padding: 6px;
        border-radius: 12px;
        margin: 0 10px;
        flex: 1;
    }

    .browser_search p,
    .browser_search i {
        font-size: 9px;
        line-height: 11px;
    }

    .content_area.neg_margin {
        margin: 0 -50px;
        padding: 0;
    }

    .area_wpr {
        border-bottom: 1px solid #eeeeee;
        padding: 20px 50px 40px;
    }

    .area_wpr:last-of-type {
        border: 0;
    }

    .button_list {
        position: relative;
        overflow: hidden;
        margin: 35px 0 15px;
    }
    .cell .button_list{
        margin: 45px 0 10px;
    }

    .cell .button_list .button_center {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #fff;
        padding: 10px 20px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 45px;
        font-family: 'Inter', sans-serif;
    }

    .button_list .button_left {
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: #fff;
        padding: 15px 30px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        width: auto;
        font-family: 'Inter', sans-serif;
    }

    .button_list > .button_right {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #fff;
        padding: 15px 30px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        width: auto;
        float: right;
    }

    .tab .download_list li h5,
    .cell .download_list li h5{
        font-size: 16px;
    }

    .tab .download_list li h5 label,
    .cell .download_list li h5 label{
        font-size: 13px;
        line-height: 18px;
    }

    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }

    .client_card .card_header .user_box {
        margin-left: auto;
    }
    .media_title{
        padding: 15px 0;
        /* background: #fff; */
    }
    /* .media_title.bord-b{
        border: 1px solid #e9e9e9;
        border-top: 0;
        border-radius: 0 0 8px 8px;
    } */
    /* .media_title.bord-t{
        border: 1px solid #e9e9e9;
        border-bottom: 0;
        border-radius: 8px 8px 0 0;
    } */
    .video_wpr img{
        border-radius: 8px;
    }
    .video_wpr.t-round img{
        border-radius: 8px 8px 0 0 ;
    }

    .alt_prev{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }
    .alt_prev li{
        font-size: 13px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 0 12px;
        border-right: 1px solid #d9d9d9;
        cursor: pointer;
    }
    .alt_prev li:last-child{
        border: 0;
    }
    .alt_prev li.active{
        color: #2f7eed;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
    }
    .cell .sms_preview{
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
        border-radius: 10px;
    }
    .cell .msg_preview:after, .cell .sms_preview:after{
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }
    .cell .sms_preview:after{
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview :deep(p) {
        font-size: 11px !important;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }
    .cell .msgFooter :deep(p){
        font-size: 11px;
        line-height: 17px;
    }
    .cell .msgFooter :deep(p a){
        text-decoration: none;
    }
    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        /* border-bottom: 1px solid #eaeaea; */
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }
    .global_setting .section_header h2 span{
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #6a6a6a;
        display: block;
        padding: 3px 0;
    }
    .global_setting .tabs_btn li.close_btn {
        padding: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #dfdfdf;
        display: none;
        align-items: center;
        justify-content: center;
        color: #2f7eed;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 9;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    }
    .global_setting .tabs_btn li.close_btn img {
        max-width: 14px;
        height: auto;
        transform: scaleX(-1);
    }

    .btn_list {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
    }

    .btn_list li {
        background: #fff;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 3px 10px;
        cursor: pointer;
    }
    .global_setting .tabs_content .section_content {
        position: relative;
    }

    .global_setting .tabs_content .section_content:after {
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right: 20px;
        border-bottom: 1px solid #e9e9e9;
    }

    .global_setting .tabs_content .section_content.show:after,
    .global_setting .tabs_content .section_content:last-of-type:after,
    .global_setting .tabs_content .section_content.open_area:after {
        border: 0;
    }

    .global_setting .tabs_content .drag_catagories .section_content:after {
        border-bottom: 1px solid #e9e9e9;
    }

    .global_setting .tabs_content .drag_catagories .section_content.show:after {
        border: 0;
    }

    .global_setting .tabs_content .section_content .toggle_content {
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .global_setting .tabs_content .section_content.show .toggle_content {
        max-height: 10000000px;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .global_setting .tabs_content .section_content.show .toggle_section i {
        transform: rotate(-180deg);
    }
    .section_content .section_header .status {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }
    .toggle_section {
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }

    .toggle_section i {
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }
    .info_bar {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        margin: 0 auto;
        position: relative;
    }

    .info_bar .tutorial_btn {
        position: absolute;
        left: 100%;
        top: 25px;
        padding: 4px 10px;
        background: #fff;
        border-radius: 11px;
        font-size: 11px;
        line-height: 14px;
        color: #2f7eed;
        font-weight: 500;
        margin-left: 5%;
        cursor: pointer;
    }
    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }

    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }

    .section_content .setting_btn{
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }
    .section_content .description{
        background: #fff;
        padding: 0 15px;
        border-radius: 5px;
        border: 0;
        position: absolute;
        right: 20px;
        top: 100%;
        z-index: 2;
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .section_content .description .field_wpr{
        background: #fff;
    }

    .section_content .description.show{
        border: 1px solid #f5f5f5;
        box-shadow: 0 1px 20px rgb(0,0,0,0.15);
        max-height: 500px;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .capsule_elm{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .capsule_elm h5{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        margin-right: 12px;
    }
    .capsule_elm h5 span{
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #6a6a6a;
        display: block;
        padding: 3px 0;
    }

    .cell .mobile_menu{
        display: flex;
        padding: 30px 15px 0 15px;
        /* margin-bottom: -15px; */
    }
    .cell .mobile_menu li {
        font-size: 13px;
        line-height: 17px;
    }
    .slide_box_container{
        height: 610px;
        position: absolute;
        top: 60px;
        bottom: 80px;
        left: 15px;
        right: 15px;
        overflow: hidden;
        z-index: 2;
    }
    .slide_box {
        padding: 20px;
        border-radius: 20px 20px 0 0;
        background: #fff;
        position: absolute;
        left: 1px;
        right: 1px;
        bottom: -100%;
        max-height: 430px;
        transition: all .3s ease-in-out;
        box-shadow: 0 -2px 30px rgba(0,0,0,.15);
        text-align: left;
        z-index: 3;
        overflow-y: auto;
    }
    .slide_box::-webkit-scrollbar{
        width: 4px;
    }
    .slide_box::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 2px;
    }
    .slide_box.active{
        bottom: 1px;
    }
    .preview_content .slide_box .close_btn{
        position: sticky;
        float: right;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        background: rgba(50,55,59,.6);
        color: #fff;
    }
    :deep(.slide_box .instructor p){
        font-size: 15px;
        line-height: 27px;
        font-weight: 300;
        color: #0E101A;
    }
    .slide_box .product_card{
        width: 100%;
        overflow: visible;
        border: 0;
        border-radius: 0;
        margin: 0;
        padding-bottom: 15px;
    }
    .slide_box .product_card h3{
        padding: 0;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 15px;
    }
    @media(max-width: 1199px){
        .global_setting .tabs_btn li.close_btn{
            display: flex;
        }
        .global_setting .tabs_content .section_content:after{
            left: 10px;
            right: 10px;
        }
    }
    @media(max-width: 499px){
        .cover_type{
            margin: 15px -15px;
        }
        .global_setting .tabs_content .section_content:after{
            left: 0;
            right: 0;
        }
        .toggle_section{
            margin-left: 10px;
        }
    }
</style>
